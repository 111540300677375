import React, { useState, useEffect } from 'react';
import Header from '../Header'; 
import { useNavigate  } from 'react-router-dom';

function BestMemeCoinReview() {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    const privacyLink = () => {
        navigate('/privacy/');
    };
  
    const termsLink = () => {
        navigate('/terms/');
    };

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await fetch('https://api.thenewsapi.com/v1/news/all?api_token=cgELgTv2c2gWZcFJDB5iOueVPc0PUlCxMnWz8D2H&language=en&limit=10&search=tech|Cybersecurity|robotics|Digital|Google|Apple|Microsoft|Amazon|Facebook|Meta|Tesla|Intel|Twitter|SpaceX|AI|server|cyber|data breach');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setArticles(data.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchArticles();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div className="App">
            <Header />
            <header
                className="w-full bg-cover bg-center"
                style={{
                    height: "24rem",
                    backgroundImage: "url(/tech-background-t29vt7psb6i6sgcy.jpg)",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center"
                }}
            >
                <div className="flex items-center justify-center h-full w-full bg-gray-900 bg-opacity-70">
                    <div className="text-center">
                        <h1 className="text-4xl text-white font-bold leading-tight">
                        Top Stories
                        </h1>
                    </div>
                </div>
            </header>
            <main className="container mx-auto py-8 px-4 lg:px-0">
                <article className="max-w-4xl mx-auto rounded-lg overflow-hidden">
                    <div className='pt-8'>
                        <div className="container mx-auto p-4">
                            {articles.map(article => (
                            <div className="bg-white p-6 rounded-lg shadow-md mb-10" key={article.uuid}>
                                <img src={article.image_url} /><br />
                                <h2 className="text-xl font-bold mb-2">{article.title}</h2>
                                <p className="text-gray-700">{article.description}</p>
                                <a className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 mt-10" href={article.url} target="_blank" rel="noopener noreferrer" style={{ display: 'block' }}>Read more</a>
                            </div>
                            ))}
                        </div>

                    </div>
                </article>
            </main>
            <footer className="bg-gray-900 text-white py-6">
        <div className="container mx-auto text-center">
          <p className="mt-2 text-white-600 leading-relaxed">
            Disclaimer
            <br />
            We strive to provide accurate and timely information, but cannot guarantee
            that all content on this site is correct or up-to-date. Our articles and
            reports are intended for informational purposes only, and we do not
            endorse any specific products or investments. Readers are advised to
            verify the facts and conduct their own research before making any
            decisions based on the information provided here.
          </p><br/>
          <p><span onClick={privacyLink} style={{cursor: "pointer"}}>Privacy Policy</span> | <span onClick={termsLink} style={{cursor: "pointer"}}>Terms of Use</span></p>
          <p className="mt-5">© 2024 The Tech Leaders. All rights reserved.</p>
        </div>
      </footer>
        </div>
    );
}

export default BestMemeCoinReview;
