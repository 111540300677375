import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import BestMemeCoinReview from './pages/BestMemeCoinReview';
import BestMemeCoinReview2024 from './pages/BestMemeCoinReview2024';
import BestMemeCoinReview7 from './pages/BestMemeCoinReview7';
import PrivacyPolicy from './pages/Privacy';
import TermsofUse from './pages/TermsofUse';

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/review/best-meme-coin" element={<BestMemeCoinReview />} />
        <Route path="/reviews/6/best-meme-coins-of-2024" element={<BestMemeCoinReview2024 />} />
        <Route path="/reviews/7/best-meme-coins-of-2024" element={<BestMemeCoinReview7 />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsofUse />} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
