export const reportConversion = (url) => {
    const callback = () => {
      if (typeof url !== 'undefined') {
        // Optional: handle URL redirection or other logic here
      }
    };
  
    window.gtag('event', 'conversion', {
      send_to: 'AW-627825002/OODsCM3ByscZEOqyr6sC',
      event_callback: callback,
    });
  };